<template>
  <div class="sort-wrap">
    <a-select
      style="width: 200px;"
      :default-value="defaultValue.scene || ''"
      @change="(value) => {onChange(value, 'scene')}"
    >
      <a-select-option value>所有场景</a-select-option>
      <a-select-option
        v-for="(item, index) in filterData"
        :key="index"
        :value="item.label_id"
      >{{item.name}}</a-select-option>
    </a-select>

    <div class="radio-button-wrap">
      <a-radio-group
        :default-value="defaultValue.mode || defaultMode"
        @change="(event) => {onChange(event.target.value, 'mode')}"
      >
        <a-radio-button
          class="ft14"
          v-for="item in FilterSonglistOptions"
          :value="item.value"
          :key="item.value"
        >{{item.label}}</a-radio-button>
      </a-radio-group>
    </div>
  </div>
</template>
<script>
  import {FilterSonglistOptions} from '@/config/options'

  export default {
    props: {
      filterData: Array,
      default: [],
      defaultValue: {
        type: Object,
        default() {
          return {}
        }
      }
    },

    data() {
      return {
        FilterSonglistOptions,
        defaultMode: FilterSonglistOptions[0].value,
        listParams: {}
      };
    },

    methods: {
      handleChange() { },
      onChange(value, key) {
        this.listParams[key] = value || '';

        if (key === 'scene') {
          this.$root.$goSearch({
            scene: value
          })
        }

        this.$emit('change', this.listParams)
      }
    },
  };
</script>

<style lang="scss" >
  // mobile
  @media screen and (max-width: $screen-width-md) {
    .sort-wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      .radio-button-wrap {
        .ant-radio-button-wrapper {
          padding: 0 11px;
          height: 40px;
          line-height: 40px;
          color: rgba(0, 0, 0, 0.85);
        }
        .ant-radio-button-wrapper-checked {
          background-color: $primary-color;
          color: #fff;
        }
      }
      .ant-select-selection--single {
        height: 40px;
        color: rgba(0, 0, 0, 0.85);
        .ant-select-selection-selected-value {
          line-height: 40px;
        }
        .ant-select-arrow {
          margin-top: -5px;
        }
      }
    }
  }
  // pc
  @media screen and (min-width: $screen-width-md) {
    .sort-wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      .radio-button-wrap {
        .ant-radio-button-wrapper {
          padding: 0 11px;
          height: 40px;
          line-height: 40px;
          color: rgba(0, 0, 0, 0.85);
        }
        .ant-radio-button-wrapper-checked {
          background-color: $primary-color;
          color: #fff;
        }
      }
      .ant-select-selection--single {
        height: 40px;
        color: rgba(0, 0, 0, 0.85);
        .ant-select-selection-selected-value {
          line-height: 40px;
        }
        .ant-select-arrow {
          margin-top: -5px;
        }
      }
    }
  }
</style>