<template>
  <div class="song-list-page-wrap">
    <!--<h1 class="title border-none flex cen-start container m-bottom-md">歌单</h1>-->

    <div class="container" style="padding-top:20px;">
      <SongListForm
        :default-value="filterDefaultValue"
        :filterData="filterData"
        @change="onChangeFilter"
      ></SongListForm>

      <ng-load-page
        class="m-top-base song-list-box"
        keyId="playlist_id"
        :request="$axios.Music_Play_List"
        :params="listParams"
        :changeData="changeItem"
        :reload.sync="reloadTable"
      >
        <template v-slot:default="{data}">
          <a-row :gutter="20">
            <a-col
              v-for="item in data"
              class="m-bottom-md"
              :xs="12"
              :sm="12"
              :md="4"
              :key="item.playlist_id"
            >
              <song-list-item :item="item" isShowCollect @update="updateListItem"></song-list-item>
            </a-col>
          </a-row>
        </template>
      </ng-load-page>
    </div>
  </div>
</template>
<script>
  import NgLoadPage from '@/components/NgLoadPage.vue'
  import SongListForm from "./components/songListForm.vue";
  import SongListItem from '@/pages/components/songList/SongListItem.vue'

  export default {
    props: {
      data: {
        type: Array,
      },
    },
    data() {
      return {
        filterData: [],
        genreList: [],
        listParams: {},
        changeItem: [],
        reloadTable: false,
        filterDefaultValue: {}
      };
    },
    components: {
      NgLoadPage,
      SongListForm,
      SongListItem,
    },

    created() {
      let query = this.$route.query;

      this.$axios.CommonLabels({type: 2}).then(res => {
        const data = res.data;

        if (data && data.code == 0) {
          this.filterData = data.data ? data.data.list[0].child : []
        }
      })

      this.filterDefaultValue.scene = query.scene;
      this.listParams.label_id = query.scene;
      this.listParams.mode="1";
      this.listParams.sort_type="1"
    },

    methods: {
      handleCollect(i) {
        this.genreList[i].is_collected = this.genreList[i].is_collected ? 0 : 1
      },

      onChangeFilter(params) {
        params.sort_type = params.mode;
        params.label_id = params.scene;

        this.listParams = {...params};
      },

      updateListItem(scope) {
        this.changeItem = [scope];

        this.$nextTick(() => {
          this.changeItem = [];
        })
      }
    },
  };
</script>
<style lang="scss" scoped>
  ::v-deep {
    .song-list-box {
      .ant-list-items {
        .ant-list-item {
          display: inline-block;
          width: 15%;
        }
      }
    }
  }

  .song-list-page-wrap {
    .title {
      height: 88px;
      line-height: 88px;
      border-bottom: 1px solid $border-color;
    }
  }

  // mobile
  @media screen and (max-width: $screen-width-md) {
    .song-list-page-wrap {
      // padding-bottom: 3.2rem;
    }
  }
  // pc
  @media screen and (min-width: $screen-width-md) {
    .song-list-page-wrap {
    }
  }
</style>